<template>
  <div class="px-5">
    <Form
      title="Detalles del 11 ideal"
      ref="elevenForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form2.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.elevenForm.form.id,
            status: !this.$refs.elevenForm.visible ? 1 : 0
          },
          route: "/eleven",
          module: "eleven"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.elevenForm.form
      this.loading = true

      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            id_category: form.id_category,
            id_subcategory: form.id_subcategory,
            name: form.name
          },
          route: "/eleven/update",
          module: "eleven"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push(
              "/torneos/" +
                this.$route.params.id +
                "/categorias/" +
                this.$route.params.id2 +
                "/academias"
            )
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deletePlayer() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al 11 ideal.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.elevenForm.form.id,
                  status: 2
                },
                route: "/eleven",
                module: "eleven"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200)
                  this.$router.push(
                    "/torneos/" +
                      this.$route.params.id +
                      "/categorias/" +
                      this.$route.params.id2 +
                      "/academias"
                  )
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/eleven",
          module: "eleven",
          id: this.$route.params.id3
        })
        .then((response) => {
          this.form = response.data
          this.$refs.elevenForm.form = this.form
          this.$refs.elevenForm.visible =
            response.data && response.data.active === 1 ? true : false
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
