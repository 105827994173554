<template>
  <div class="px-5">
    <t-card class="w-full" variant="form">
      <div class="flex justify-between px-3 bg-white py-4 rounded-t-md mt-3">
        <p class="uppercase font-bold text-sm my-auto text-center">
          {{ title }}
        </p>
        <div class="flex items-center">
          <t-button
            type="button"
            class="mr-2"
            variant="error"
            @click="$router.go(-1)"
            >Volver</t-button
          >
          <t-button type="button" @click="edit">
            Editar
            <t-loader v-if="loading" extraClass="ml-2" />
          </t-button>
        </div>
      </div>

      <div class="flex flex-col px-4 bg-gray-100 pb-8">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('tournament')
                  ? errors.first('tournament')
                  : ''
              "
              :variant="
                showValidation && errors.first('tournament') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Torneo'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.tournament.name"
                name="tournament"
                id="tournament"
                placeholder="Ingrese la academia"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('tournament') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                placeholder="Ingrese el nombre del 11 ideal"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1">
            <t-input-group
              :feedback="
                showValidation && errors.first('category')
                  ? errors.first('category')
                  : ''
              "
              :variant="
                showValidation && errors.first('category') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categoría'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.category.name"
                name="category"
                id="category"
                placeholder="Ingrese la categoría"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('category') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('subcategory')
                  ? errors.first('subcategory')
                  : ''
              "
              :variant="
                showValidation && errors.first('subcategory') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Subcategoría'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.subcategory.name"
                name="subcategory"
                id="subcategory"
                placeholder="Ingrese la subcategoría"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('subcategory') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>
          </div>
        </div>

        <br />

        <div class="justify-center">
          <div class="my-4 grid grid-cols-5 gap-5 mx-1 justify-center">
            <div></div>
            <div></div>
            <div
              v-for="(data, index) of form.players.slice(0, 1)"
              :key="'J1-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
            <div></div>
            <div></div>
          </div>

          <br />

          <div class="my-4 grid grid-cols-5 gap-5 mx-1 justify-center">
            <div
              v-for="(data, index) of form.players.slice(1, 3)"
              :key="'J2-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
            <div></div>
            <div
              v-for="(data, index) of form.players.slice(3, 5)"
              :key="'J3-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
          </div>

          <br />

          <div class="my-4 grid grid-cols-5 gap-5 mx-1 justify-center">
            <div></div>
            <div
              v-for="(data, index) of form.players.slice(5, 8)"
              :key="'J4-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
            <div></div>
          </div>

          <br />

          <div class="my-4 grid grid-cols-5 gap-5 mx-1 justify-center">
            <div
              v-for="(data, index) of form.players.slice(8, 9)"
              :key="'J5-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
            <div></div>
            <div
              v-for="(data, index) of form.players.slice(9, 10)"
              :key="'J6-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
            <div></div>
            <div
              v-for="(data, index) of form.players.slice(10)"
              :key="'J7-' + index"
            >
              <img
                :src="data.player.url_profile_picture"
                alt="Jugador"
                style="
                  height: 100px;
                  border-radius: 50%;
                  aspect-ratio: 1;
                  object-fit: cover;
                "
              />
              <p>
                {{
                  data.player.jersey_number +
                  ". " +
                  data.player.first_name.substring(0, 1) +
                  ". " +
                  data.player.last_name
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        name: null,
        id_tournament: null,
        id_category: null,
        id_subcategory: null,
        players: [
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          },
          {
            id_academy: null,
            id_player: null,
            player: { url_profile_picture: null }
          }
        ],
        tournament: { name: null },
        category: { name: null },
        subcategory: { name: null }
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },

    edit() {
      this.$router.push(this.$route.path.replace("/detalles", "/editar"))
    }
  },
  created() {}
}
</script>
